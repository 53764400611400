import adminEndPoints from './adminEndPoints';
import authEndPoints from './authEndPoints';
import studiesEndPoints from './studiesEndPoints';
import billingEndPoints from './billingEndPoints';
import locationsEndPoints from './locationsEndPoints';

export default {
    ...authEndPoints,
    ...studiesEndPoints,
    ...adminEndPoints,
    ...billingEndPoints,
    ...locationsEndPoints
};
