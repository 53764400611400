import store from "redux/store";
import moment from "moment";
const locationsEndPoints = {
  locationsList: async ({ searchText, page = 1 }, callback) => {
    let baseURL = process.env.REACT_APP_API_URL;
    // baseURL = '/apis.dbsystem/apithink/web/index.php';
    console.log('entrooooo-------->', baseURL);
    let url = `${baseURL}/sucursal/listado`;
    var settings = {
      url: url,
      method: "GET",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await window.$.ajax(settings);
  },
};

export default locationsEndPoints;
